<script setup lang="ts">
import { onBeforeMount, onMounted, ref, shallowRef } from 'vue'
import EmailForm from '@/components/auth/EmailForm.vue'
import type { GenProfileDto, Profile } from '@/models/profile'
import UsernameForm from '@/components/auth/UsernameForm.vue'
import BirthForm from '@/components/auth/BirthForm.vue'
import GenderForm from '@/components/auth/GenderForm.vue'
import LocationForm from '@/components/auth/LocationForm.vue'
import MyPhotoForm from '@/components/auth/MyPhotoForm.vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import CoreHeader from '@/components/common/CoreHeader.vue'
import JoinClub from '@/components/auth/JoinClub.vue'
import { useProfileStore } from '@/stores/profile'
import { notify } from '@kyvg/vue3-notification'
import { storeToRefs } from 'pinia'
import MobileLayout from '@/components/core/MobileLayout.vue'
import { useAuth } from '@/composables/useFirebase'
import PhoneForm from '@/components/auth/PhoneForm.vue'

const stepComp = shallowRef([UsernameForm, PhoneForm])
const stepIndex = ref(0)
const profile = ref<GenProfileDto>({})
const router = useRouter()
const profileStore = useProfileStore()
const { me } = profileStore
const { auth } = useAuth()
const user = auth.currentUser
onBeforeMount(() => {
  if (!user) {
    router.push('/login')
    return
  }
  profile.value = {
    email: user.email!
  }
})

async function updateProfile(data: { key: string; value: any }) {
  if (data) {
    profile.value[data.key] = data.value
  }
  console.log(profile.value, stepIndex.value)
  if (stepIndex.value === 1) {
    try {
      axios
        .patch('/dinners/' + profileStore.resId, profile.value)
        .then((res) => {
          console.log(res)
          signUpSuccess(res.data)
        })
        .catch((err) => {
          console.log(err)
          notify({
            type: 'error',
            title: 'Error',
            text: err.response.data.message
          })
        })
    } catch (e) {
      console.log(e)
      notify({
        type: 'error',
        title: 'Error',
        text: '회원가입 중 오류가 발생했습니다.'
      })
    }
  } else {
    stepIndex.value += 1
  }
}

function signUpSuccess(user: Profile) {
  me.profile = user
  router.push('/?signup=success')
}

function back() {
  if (stepIndex.value > 0) {
    stepIndex.value -= 1
  }
}

async function uploadImage() {
  const formData = new FormData()
  profile.value.profiles?.forEach((file: any) => {
    formData.append('media', file)
  })
  try {
    profile.value.images = [(await axios.post('media/upload', formData)).data.url]
  } catch (e) {
    console.log(e)
    notify({
      type: 'error',
      title: 'Error',
      text: '프로필 사진 업로드 중 오류가 발생했습니다.'
    })
    stepIndex.value -= 1
    return
  }
}
</script>

<template>
  <mobile-layout>
    <div>
      <core-header @back="back" />
      <div class="signup-view flex justify-center items-start px-4">
        <div v-if="user" class="mobile-container">
          <component
            :is="stepComp[stepIndex]"
            :profile="profile"
            @next="updateProfile"
            :key="stepIndex"
          />
        </div>
      </div>
    </div>
  </mobile-layout>
</template>
<style scoped lang="scss">
.signup-view {
  padding-top: 100px;
  min-height: 600px;
}

.mobile-container {
  width: 100%;
  max-width: 900px;
}
</style>
