import { useProfileStore } from '@/stores/profile'
import axios from 'axios'
import { useAuth } from '@/composables/useFirebase'

export function getMe() {
  const profileStore = useProfileStore()
  const { auth } = useAuth()
  const user = auth.currentUser
  console.log('auth: ', user)
  if (!user) {
    return
  }
  axios
    .get('/dinners/email/' + user?.email)
    .then((res) => {
      profileStore.me.profile = res.data
    })
    .catch(() => {
      //
    })
}
