import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
  GoogleAuthProvider,
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  getRedirectResult
} from 'firebase/auth'
export function useAuth() {
  const firebaseConfig = {
    apiKey: 'AIzaSyCk33GxDusytueDrjnJBCiCxsSoO4z70sI',
    authDomain: import.meta.env.PROD ? 'ogoto.co' : 'beminor-742a2.firebaseapp.com',
    projectId: 'beminor-742a2',
    storageBucket: 'beminor-742a2.appspot.com',
    messagingSenderId: '910401045025',
    appId: '1:910401045025:web:14fd2644664afc18eb9960',
    measurementId: 'G-9WMMS47WFL'
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig)
  const analytics = getAnalytics(app)
  const auth = getAuth(app)
  const provider = new GoogleAuthProvider()

  function signIn(): Promise<{ user?: any; token?: string }> {
    return new Promise((resolve, reject) => {
      if (import.meta.env.PROD || true) {
        signInWithRedirect(auth, provider)
        resolve({})
      } else {
        signInWithPopup(auth, provider)
          .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result)
            resolve({ user: result.user, token: credential?.accessToken })
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      }
    })
  }

  function getRedirect(): Promise<{ user: any; token?: string }> {
    return new Promise((resolve, reject) => {
      getRedirectResult(auth)
        .then((result) => {
          console.log('result', result)
          if (!result) {
            reject()
            return
          }
          const credential = GoogleAuthProvider.credentialFromResult(result)
          resolve({ user: result.user, token: credential?.accessToken })
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }

  return { auth, signIn, getRedirect }
}
