<script setup lang="ts">
import { useDisplay } from 'vuetify'
import { VBottomSheet } from 'vuetify/labs/components'
import { computed } from 'vue'

const props = defineProps<{
  modelValue: boolean
  persistent?: boolean
}>()

const emit = defineEmits<{
  'update:modelValue': [modelValue: boolean]
}>()

const showLayer = computed({
  get() {
    return props.modelValue
  },
  set(newValue) {
    emit('update:modelValue', newValue)
  }
})

const { xs } = useDisplay()
</script>

<template>
  <v-bottom-sheet v-if="xs" v-model="showLayer" :persistent="persistent ?? true">
    <div class="surface on-background-text p-6 rounded-t-3xl rounded-b-none">
      <slot />
    </div>
  </v-bottom-sheet>
  <v-dialog v-else v-model="showLayer" :persistent="persistent ?? true" max-width="504">
    <div class="surface on-background-text px-10 py-8 rounded-3xl">
      <slot />
    </div>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
