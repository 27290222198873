<script setup lang="ts">
import { useProfileStore } from '@/stores/profile'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'

const router = useRouter()
const profileStore = useProfileStore()
const { me } = storeToRefs(profileStore)
// const joinedClub = computed(() => {
//   return (
//     me.value?.profile?.roles.map((role: string) => {
//       return clubs.find((x) => x.role === role)?.name.replace(/-/g, ' ') ?? ''
//     }) ?? []
//   )
// })
function signout() {
  me.value.profile = null
  document.cookie = 'Access_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
  router.push('/')
}
function goToDiscord() {
  window.open(import.meta.env.VITE_DISCORD_INVITE, '_blank')
}
</script>
<template>
  <div class="setting-menu">
    <!--    <div class="club py-3 px-5">-->
    <!--      <h2 class="title-medium mb-2 text-black">Club</h2>-->
    <!--      <p class="body-large text-black">{{ joinedClub.join(', ') }}</p>-->
    <!--    </div>-->
    <div class="menu">
      <div class="menu-item flex justify-between items-center" @click="goToDiscord">
        <p>Go to Discord</p>
        <v-icon icon="mdi-menu-right" />
      </div>
      <div class="menu-item flex justify-between items-center" @click="router.push('/mypage/edit')">
        <p>Edit Profile</p>
        <v-icon icon="mdi-menu-right" />
      </div>
      <!--      <div class="menu-item flex justify-between items-center">-->
      <!--        <p>Settings</p>-->
      <!--        <v-icon icon="mdi-menu-right" />-->
      <!--      </div>-->
      <div class="menu-item flex justify-between items-center">
        <p>Help Center</p>
        <v-icon icon="mdi-menu-right" />
      </div>
      <div class="menu-item flex justify-between items-center" @click="signout">
        <p class="body-large">Logout</p>
        <v-icon icon="mdi-menu-right" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.club {
  border-bottom: 1px solid var(--md-sys-color-outline-variant);
}

.menu {
  padding: 68px 8px;
}

.menu-item {
  cursor: pointer;
  padding: 16px 16px;
  border-bottom: 1px solid var(--md-sys-color-outline-variant);

  &:first-of-type {
    border-top: 1px solid var(--md-sys-color-outline-variant);
  }

  .v-icon {
    transition: margin-right 0.1s ease-in-out;
  }

  &:hover {
    .v-icon {
      margin-right: -10px;
    }
  }
}
</style>
