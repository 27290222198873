<script setup lang="ts">
import { RouterView } from 'vue-router'
import { onMounted } from 'vue'
import { getMe } from '@/middleware/auth'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
onMounted(() => {
  getMe()
  dayjs.locale('ko')
})
</script>

<template>
  <notifications />
  <v-app style="background-color: #f5f1e9">
    <RouterView />
  </v-app>
</template>

<style scoped></style>
