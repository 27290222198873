import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SignupView from '@/views/SignupView.vue'
import MyPageView from '@/views/MyPageView.vue'
import MyPageIndex from '@/views/myprofile/MyPageMenu.vue'
import MyPageEdit from '@/views/myprofile/MyPageEdit.vue'
import LoginView from '@/views/LoginView.vue'
import DinnerFormView from '@/views/DinnerFormView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      children: [
        {
          name: 'app',
          path: '/',
          component: () => import('@/views/AppView.vue')
        },
        {
          name: 'mydinners',
          path: '/mydinners',
          component: () => import('@/views/MyDinnersView.vue')
        }
      ]
    },
    {
      path: '/bookclub/:id',
      name: 'book',
      component: () => import('@/views/BookDetail.vue')
    },
    {
      path: '/checkout',
      name: 'checkout',
      children: [
        {
          name: 'checkoutSuccess',
          path: 'success',
          component: () => import('@/views/checkout/SuccessView.vue')
        },
        {
          name: 'checkoutFail',
          path: 'fail',
          component: () => import('@/views/checkout/FailView.vue')
        },
        {
          name: 'checkoutIndex',
          path: ':id',
          component: () => import('@/views/CheckoutView.vue')
        }
      ]
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignupView
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/dinner-form',
      name: 'dinner-form',
      component: DinnerFormView
    },

    {
      path: '/mypage',
      name: 'MyPage',
      component: MyPageView,
      children: [
        {
          path: '',
          component: MyPageIndex
        },
        {
          path: 'edit',
          component: MyPageEdit
        }
      ]
    },
    {
      name: 'Withdraw',
      path: '/withdraw',
      component: () => import('@/views/myprofile/withdraw.vue')
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutView.vue')
    }
  ]
})

export default router
