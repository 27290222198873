<script setup lang="ts">
import { onMounted } from 'vue'
import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'
import { v4 as uuidv4 } from 'uuid'
import { useRouter } from 'vue-router'
import { useProfileStore } from '@/stores/profile'

const store = useProfileStore()
const router = useRouter()
onMounted(() => {
  store.resId = uuidv4()
  createWidget('cUVLWFer', {
    container: document.querySelector('#typeform') as HTMLElement,
    hidden: {
      resid: store.resId
    },
    onEndingButtonClick() {
      router.push('/login')
    }
  })
})
</script>

<template>
  <div id="typeform"></div>
</template>

<style scoped lang="scss">
#typeform {
  height: 100%;
}
</style>
