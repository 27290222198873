<script setup lang="ts">
import { useProfileStore } from '@/stores/profile'
import { storeToRefs } from 'pinia'
import { computed, inject, onBeforeMount, type Ref, ref, watch } from 'vue'
import type { Profile } from '@/models/profile'
import dayjs from 'dayjs'
import { VBottomSheet, VDatePicker } from 'vuetify/labs/components'
import GeoService from '@/lib/geo-service'
import axios from 'axios'
import { useNotification } from '@kyvg/vue3-notification'
import { useRouter } from 'vue-router'
import { notifyMessage } from '@/lib/notify'

const profileStore = useProfileStore()
const { me } = storeToRefs(profileStore)
const username = ref('')
const gender = ref('')
const birth = ref('')
const profileImageFile = inject<Ref<File | null>>('newProfileImage')
const selectedBirth = ref([])
onBeforeMount(() => {
  initData(me.value.profile)
})

watch(me, (value) => {
  initData(value.profile)
})

function initData(me: Profile | null) {
  username.value = me?.username ?? ''
  gender.value = me?.gender ?? ''
  birth.value = me?.birth ?? ''
  // location.value = me?.hometown ?? ''
  // items.value = [me?.hometown ?? '']
}

const genderOptions = [
  {
    label: 'Woman',
    value: 'woman'
  },
  {
    label: 'Man',
    value: 'man'
  },
  {
    label: 'Prefer not to say',
    value: 'prefer_not_to_say'
  }
]

const formatBirth = computed(() => {
  return dayjs(birth.value).format('YYYY-MM-DD')
})
const age = computed(() => {
  return dayjs().diff(birth.value, 'year')
})
const sheet = ref(false)

function birthSave() {
  sheet.value = false
  birth.value = dayjs(selectedBirth.value[0]).toISOString()
}

const location = ref('')
const loading = ref(false)
const search = ref<string | undefined>(undefined)
const items = ref<string[]>([])
const cityOptions = computed(() => {
  console.log(GeoService.getCity())
  return GeoService.getCity()
})
watch(search, (val: string | undefined) => {
  val && val !== location.value && querySelections(val)
})

function querySelections(v: string) {
  loading.value = true
  // Simulated ajax query
  setTimeout(() => {
    items.value = cityOptions.value.filter((e) => {
      return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
    })
    loading.value = false
  }, 500)
}
const enableSave = computed(() => {
  return (
    username.value !== me.value?.profile?.username ||
    gender.value !== me.value?.profile.gender ||
    birth.value !== me.value?.profile.birth ||
    // location.value !== me.value?.profile.hometown ||
    profileImageFile?.value
    // openGender.value !== me.value?.profile.openGender ||
    // openAge.value !== me.value?.profile.openAge
  )
})

async function uploadImage(): Promise<string | undefined> {
  if (!profileImageFile?.value) {
    return
  }
  try {
    const formData = new FormData()
    formData.append('media', profileImageFile.value as File)
    const res = await axios.post('media/upload', formData)
    return res.data.url
  } catch (e) {
    notifyMessage('이미지 업로드에 실패했습니다.', 'error')
  }
}

const { notify } = useNotification()
const router = useRouter()
async function save() {
  try {
    const imageUrl = await uploadImage()
    const forUpdate = {
      username: username.value,
      gender: gender.value,
      images: [imageUrl],
      birth: birth.value,
      hometown: location.value,
      openGender: openGender.value,
      openAge: openAge.value
    }
    const res = await axios.patch('/profiles/' + me.value?.profile?._id, forUpdate)
    me.value.profile = Object.assign({}, me.value.profile, res.data)
    notify({ type: 'success', title: 'Success', text: '프로필이 업데이트 되었습니다.' })
    router.back()
  } catch (e) {
    notify({ type: 'error', title: 'Error', text: '프로필 업데이트에 실패했습니다.' })
  }
}

const openGender = ref(false)
const openAge = ref(false)
</script>
<template>
  <div class="edit-profile">
    <h3 class="headline-medium">기본 정보</h3>
    <div class="py-3 px-5">
      <h4 class="body-small text-black">유저네임</h4>
      <v-text-field v-model="username" hide-details />
    </div>
    <div class="py-3 px-5">
      <h4 class="body-small text-black">생일</h4>
      <v-text-field :model-value="formatBirth" hide-details readonly @click="sheet = true" />
    </div>
    <div class="pt-3 px-5">
      <h4 class="body-small text-black">지역</h4>
      <v-autocomplete v-model="location" v-model:search="search" :items="items" />
    </div>
    <p class="body-small">생일과 지역은 공개되지 않습니다.</p>
    <h3 class="headline-medium mt-10">프로필 조회 정보</h3>
    <div class="flex py-3 px-5">
      <div class="flex-1">
        <h4 class="body-small text-black">성별</h4>
        <v-select v-model="gender" :items="genderOptions" item-title="label" hide-details />
      </div>
      <div class="flex flex-col items-center justify-center">
        <h4 class="body-small text-black">공개 여부</h4>
        <v-icon
          @click="openGender = !openGender"
          :icon="openGender ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
        />
      </div>
    </div>
    <div class="flex py-3 px-5">
      <div class="flex-1">
        <h4 class="body-small text-black">나이</h4>
        <v-text-field :model-value="age" hide-details readonly />
      </div>
      <div class="flex flex-col items-center justify-center">
        <h4 class="body-small text-black">공개 여부</h4>
        <v-icon
          @click="openAge = !openAge"
          :icon="openAge ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
        />
      </div>
    </div>
    <div class="flex justify-center px-5 mt-5">
      <v-btn block :disabled="!enableSave" @click="save">저장하기</v-btn>
    </div>
    <div class="mt-14 flex justify-end">
      <router-link to="/withdraw">
        <button class="underline">탈퇴하기</button>
      </router-link>
    </div>
    <v-bottom-sheet v-model="sheet">
      <v-date-picker
        v-model="selectedBirth"
        @click:save="sheet = false"
        @click:cancel="sheet = false"
        @update:modelValue="birthSave"
      />
    </v-bottom-sheet>
  </div>
</template>
<style scoped lang="scss">
// @import "assets/styles/typography";
// @import "assets/styles/mixins";
.edit-profile {
  .v-text-field {
    margin-top: -20px;
  }
}
</style>
