<script setup lang="ts">
import CoreHeader from '@/components/common/CoreHeader.vue'
import { computed, provide, ref } from 'vue'
import { useProfileStore } from '@/stores/profile'
import { storeToRefs } from 'pinia'
import MobileLayout from '@/components/core/MobileLayout.vue'
import ImageCropper, { type CropperResult } from '@/components/common/ImageCropper.vue'
import { useRoute } from 'vue-router'
import ModalLayer from '@/components/common/ModalLayer.vue'

const profileStore = useProfileStore()
const route = useRoute()
const { me } = storeToRefs(profileStore)
const newProfileImage = ref<string | null>(null)
const cropper = ref<typeof ImageCropper | null>(null)
const file = ref<File | null>(null)

const isEdit = computed(() => route.path.includes('edit'))
function selectImage() {
  if (isEdit.value) {
    cropper.value?.selectFile()
  }
}
function getResult(result: CropperResult) {
  newProfileImage.value = result.dataURL
  file.value = result.file
}
provide('newProfileImage', file)
</script>
<template>
  <mobile-layout>
    <div class="my-page-view">
      <core-header />
      <div class="my-profile flex gap-4">
        <div class="image-box" :class="{ 'cursor-pointer': isEdit }" @click="selectImage">
          <img
            class="rounded-full"
            :src="newProfileImage ?? me.profile?.images[0]"
            alt="profile image"
          />
          <div
            v-if="isEdit"
            class="edit-icon rounded-full w-6 h-6 flex justify-center items-center"
          >
            <v-icon icon="mdi-camera" size="14" color="var(--md-ref-palette-neutral60)"></v-icon>
          </div>
        </div>
        <h2 class="display-small">{{ me.profile?.username }}</h2>
      </div>
      <router-view />
    </div>
    <image-cropper
      ref="cropper"
      mode="fixedSize"
      :file-name="me.profile!.username as string"
      @result="getResult"
    />
  </mobile-layout>
</template>
<style scoped lang="scss">
.my-profile {
  padding: 37px 34px;

  img {
    width: 64px;
    height: auto;
    aspect-ratio: 1;
    object-fit: cover;
  }
}
.image-box {
  position: relative;
  .edit-icon {
    position: absolute;
    background-color: var(--md-ref-palette-neutral95);
    right: 0;
    bottom: 0;
  }
}
</style>
