import koreaGeo from './korea-geo.json'
export default class GeoService {
  static getCity(): string[] {
    return koreaGeo.data.reduce((acc: string[], cur: any) => {
      const key = Object.keys(cur)[0]
      const values = cur[key]
      const addr = values.map((x: string) => `${key} ${x}`)
      acc = acc.concat(addr)
      return acc
    }, [])
  }
}
