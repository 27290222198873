<script setup lang="ts">
import anime from 'animejs/lib/anime.es.js'
import { computed, onMounted, ref } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'
const props = defineProps<{
  text: string
  gap: number
}>()
const splitText = computed(() => props.text.split(' '))
const split = ref<HTMLElement[]>([])
const animeText = ref<HTMLElement | null>(null)
const { stop } = useIntersectionObserver(
  animeText,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      anime({
        targets: split.value,
        translateY: ['5vw', 0],
        delay: anime.stagger(200),
        easing: 'cubicBezier(1.000, -0.005, 0.020, 0.810)',
        duration: 450
      })
      anime({
        targets: split.value,
        opacity: 1,
        delay: anime.stagger(200),
        endDelay: anime.stagger(199),
        duration: 1,
        easing: 'linear'
      })
      stop()
    }
  },
  { threshold: 1 }
)
onMounted(() => {
  // console.log(split.value)
})
</script>

<template>
  <div ref="animeText" class="anime-text">
    <span
      ref="split"
      v-for="(value, i) in splitText"
      :style="{ 'margin-right': gap + 'px' }"
      :key="i"
      >{{ value }}</span
    >
    <slot />
  </div>
</template>

<style scoped lang="scss">
.anime-text {
  span {
    font-weight: inherit;
    display: inline-block;
    opacity: 0;
  }
}
</style>
