<script setup lang="ts">
/// <reference types="vite-svg-loader" />
import MobileLayout from '@/components/core/MobileLayout.vue'
import { nextTick, onMounted } from 'vue'
import { useAuth } from '@/composables/useFirebase'
import axios from 'axios'
import { notifyError, notifyMessage } from '@/lib/notify'
import { useRouter } from 'vue-router'
import { useProfileStore } from '@/stores/profile'

const { getRedirect, signIn } = useAuth()
const profileStore = useProfileStore()
const { me } = profileStore
const router = useRouter()

onMounted(() => {
  checkoutAuth()
})

async function goLogin() {
  const { user } = await signIn()
  if (!user) return
  afterSignin(user)
}

async function checkoutAuth() {
  const { user } = await getRedirect()
  if (!user) return
  await afterSignin(user)
}
async function afterSignin(user: any) {
  console.log(user)
  try {
    const res = await axios.get('/dinners/email/' + user.email)
    console.log(res.data)
    if (res.data) {
      me.profile = res.data
      router.push('/')
    } else {
      router.push('/signup')
    }
  } catch (e) {
    notifyMessage('에러가 발생했습니다.', 'error')
  }
}
</script>

<template>
  <mobile-layout>
    <div class="login-view flex justify-center">
      <div class="mobile-container flex flex-col items-center mt-32">
        <v-btn @click="goLogin">
          <template v-slot:prepend>
            <v-icon>mdi-google</v-icon>
          </template>
          구글로 시작하기
        </v-btn>
      </div>
    </div>
  </mobile-layout>
</template>

<style scoped lang="scss">
.mobile-container {
  width: 100%;
  max-width: 900px;
}
</style>
