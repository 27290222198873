<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import type { GenProfileDto } from '@/models/profile'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'

const props = defineProps<{
  profile: GenProfileDto
}>()

const phone = ref('')

onBeforeMount(() => {
  phone.value = props.profile.phone ?? ''
})

const isValid = ref(false)
const rules = [
  (v: string) => !!v || '필수 항목입니다.',
  (v: string) => v.length > 10 || '올바른 전화번호 형식이 아닙니다.'
]

function phoneMask(phone: string) {
  console.log(phone)
  return phone
    .replace(/^(\d)/, '($1')
    .replace(/^(\(\d{3})(\d)/, '$1) $2')
    .replace(/(\d{4})(\d{1,5})/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}
function stripPhone(phone: string) {
  return phone.replace(/[^0-9]/g, '')
}
const isLoading = ref(false)
</script>

<template>
  <div>
    <h2 class="headline-small mb-3">전화번호는 무엇인가요?</h2>
    <!--    <p class="body-medium mb-6">당신을 뭐라고 부를까요?</p>-->
    <v-form v-model="isValid" validate-on="input">
      <v-text-field
        :model-value="phone"
        :rules="rules"
        placeholder="(010) 1234-5678"
        :loading="isLoading"
        @update:model-value="phone = phoneMask($event)"
      ></v-text-field>
      <div class="mt-10 flex justify-end">
        <v-btn
          color="primary"
          icon
          :disabled="!isValid"
          @click="$emit('next', { key: 'phone', value: stripPhone(phone) })"
        >
          <v-icon size="32" icon="mdi-chevron-right" />
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<style scoped lang="scss"></style>
