import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useBookStore = defineStore('book', () => {
  const books = ref([
    {
      id: 'EEsikxiaj',
      title: '10/26 북토크클럽: 무라카미 하루키',
      desc: '오고토 북클럽 이용권 - 진행자: 방장 디디 / 텍스트: <노르웨이의 숲>, <달리기를 말할 때 내가 하고 싶은 이야기>',
      img: 'book1.jpg',
      date: '2024-10-26',
      detail:
        '2024년 10월 26일 북토크클럽 이용권\n10AM-1PM 진행\n금천구 가산디지털2로 98 1동 906호에서 진행\n진행자: 방장 디디 / 텍스트: <노르웨이의 숲>, <달리기를 말할 때 내가 하고 싶은 이야기>'
    },
    {
      id: 'alDk4ixjJ',
      title: '10/27 ‘그래도 인문학’ 북토크',
      desc: '칼 구스타프 융의 <인간과 문화>를 읽고 북토크를 진행합니다. 진행자: 방장 디디',
      img: 'book2.jpg',
      date: '2024-10-27',
      detail:
        '2024년 10월 27일 북토크클럽 이용권\n10AM-1PM 진행\n금천구 가산디지털2로 98 1동 906호에서 진행\n진행자: 방장 디디 / 텍스트: 칼 구스타브 융 <인간과 문화>'
    },
    {
      id: 'ziD4Ijx',
      title: '10/28 북토크클럽: 자기계발 독서 특집',
      desc: '매주 평일 - 진행자: 방장 디디',
      img: 'book3.jpg',
      date: '2024-10-28',
      detail:
        '2024년 10월 28일 북토크클럽 이용권\n4PM-7PM 진행\n금천구 가산디지털2로 98 1동 906호에서 진행\n진행자: 방장 디디 / 텍스트: 자기계발서 편집본'
    }
  ])

  return { books }
})
