import { notify } from '@kyvg/vue3-notification'

export function notifyMessage(text: string, type: 'info' | 'error' = 'info') {
  notify({ type, text })
}

export function notifyError(err: any) {
  console.warn('notifying error', err)
  notify({ type: 'error', text: err.response.message })
}
