<script setup lang="ts">
import { ref } from 'vue'
import VuePictureCropper, { cropper } from 'vue-picture-cropper'
import { notifyError } from '@/lib/notify'
import ModalLayer from '@/components/common/ModalLayer.vue'

export interface CropperResult {
  dataURL: string
  blob: Blob | null
  file: File | null
}

const props = defineProps<{
  mode: 'fixedSize' | 'round'
  width?: number
  height?: number
  fileName: string
}>()

const pic = ref('')
const result = ref<CropperResult>({ dataURL: '', blob: null, file: null })
const inputEl = ref<HTMLInputElement | null>(null)
const isShowModal = ref(false)
const emit = defineEmits<{
  result: [result: CropperResult]
}>()
function selectFile() {
  console.log(inputEl)
  inputEl.value?.click()
}
function changeImage(e: Event) {
  pic.value = ''
  result.value.dataURL = ''
  result.value.blob = null

  // Get selected files
  const { files } = e.target as HTMLInputElement
  if (!files || !files.length) {
    return
  }

  // Convert to dataURL and pass to the cropper component
  const file = files[0]
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    // Update the picture source of the `img` prop
    pic.value = String(reader.result)

    // Show the modal
    isShowModal.value = true

    // Clear selected files of input element
    if (!inputEl.value) {
      return
    }
    inputEl.value.value = ''
  }
}
async function getResult() {
  if (!cropper) {
    return
  }
  const dataURL = cropper.getDataURL()
  const blob: Blob | null = await cropper.getBlob()
  if (!blob) {
    return
  }

  const file = await cropper.getFile({
    fileName: props.fileName
  })

  console.log({ dataURL, blob, file })
  result.value.dataURL = dataURL
  result.value.blob = blob
  result.value.file = file
  emit('result', result.value)
  isShowModal.value = false
}
async function uploadToServer(fileName: string, result: CropperResult) {
  try {
    console.log('uploadToServer', fileName, result)
    const file = new File([result.blob!], fileName)
    console.log(file)
    const formData = new FormData()
    formData.append('file', file)
    formData.append('type', 'clubMemberProfileImage')
    // const data = await usePostApi<{ fileId: string }>('media/upload/image', {
    //   body: formData
    // })
    // return data
  } catch (e) {
    notifyError(e)
  }
}
function ready() {
  console.log('cropper ready')
}
defineExpose({ selectFile, uploadToServer })
</script>
<template>
  <input
    ref="inputEl"
    type="file"
    class="hidden"
    accept="image/jpg, image/jpeg, image/png, image/gif"
    @change="changeImage"
  />
  <modal-layer v-model="isShowModal">
    <div class="tool-bar flex justify-between pb-4">
      <button class="body-16" @click="isShowModal = false">취소</button>
      <button class="body-16 text-primary-light" @click="getResult">확인</button>
    </div>
    <VuePictureCropper
      :box-style="{
        width: '100%',
        height: '100%',
        backgroundColor: '#f8f8f8',
        margin: 'auto'
      }"
      :img="pic"
      :options="{
        viewMode: 1,
        dragMode: 'move',
        aspectRatio: 1,
        cropBoxResizable: false
      }"
      :preset-mode="{
        mode: mode ?? 'fixedSize',
        width: width ?? 500,
        height: width ?? 500
      }"
      @ready="ready"
    />
  </modal-layer>
</template>
<style scoped lang="scss">
// @import "assets/styles/typography";
// @import "assets/styles/mixins";
</style>
