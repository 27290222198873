<script setup lang="ts">
function link(to: string) {
  switch (to) {
    case 'privacy':
      window.open('https://ogoto.notion.site/107f44a45b9980f4b478d745a5c47b11?pvs=4', '_blank')
      break
    case 'terms':
      window.open('https://ogoto.notion.site/107f44a45b9980b1997ef48b1c7e85dd?pvs=4', '_blank')
      break
    default:
      break
  }
}
</script>

<template>
  <v-footer class="flex flex-col">
    <div class="flex justify-center gap-6">
      <button class="label-medium" @click="link('privacy')">개인정보처리방침</button>
      <button class="label-medium" @click="link('terms')">이용약관</button>
    </div>
    <div class="p-4 break-keep label-small">
      상호 : 오고토 | 대표 : 강예지 | 개인정보보호 책임자 : 강예지 | 주소 : 서울특별시 금천구
      가산디지털2로 98, 1동 906호(가산동, IT캐슬) | 사업자등록번호 : 568-07-03157 | 이메일 :
      ogoto.co@gmail.com | 전화 : 010-7905-1175
    </div>
  </v-footer>
</template>

<style scoped lang="scss"></style>
