<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import type { GenProfileDto } from '@/models/profile'
import axios from 'axios'
import { notify } from '@kyvg/vue3-notification'

const props = defineProps<{
  profile: GenProfileDto
}>()

const username = ref('')

onBeforeMount(() => {
  username.value = props.profile.nickname ?? ''
})

const isValid = ref(false)
const rules = [
  (v: string) => !!v || '유저명은 필수 항목입니다.',
  (v: string) => v.length <= 20 || '유저명은 20자 이하로 입력해주세요.',
  (v: string) => v.length >= 2 || '유저명은 2자 이상으로 입력해주세요.',
  (v: string) =>
    /^[ㄱ-ㅎ가-힣a-zA-Z0-9_]+$/.test(v) ||
    '유저명은 한글, 영문, 숫자, 언더바(_)만 사용할 수 있습니다.'
]
const isLoading = ref(false)
const emit = defineEmits(['next'])
async function next() {
  const result = await checkUsername(username.value)
  if (result) {
    emit('next', { key: 'username', value: username.value })
  }
}

async function checkUsername(v: string) {
  console.log(v)
  try {
    const { data } = await axios.get(`/dinners/username/${v}`)
    if (data) {
      notify({ type: 'error', title: 'Error', text: '이미 사용중인 Username입니다.' })
      return false
    } else {
      return true
    }
  } catch (e) {
    notify({ type: 'error', title: 'Error', text: 'Username 체크 중 오류가 발생했습니다.' })
    return 'Username 체크 중 오류가 발생했습니다.'
  }
}
</script>

<template>
  <div>
    <h2 class="headline-small mb-3">이름이 무엇인가요?</h2>
    <p class="body-medium mb-6">당신을 뭐라고 부를까요?</p>
    <v-form v-model="isValid" validate-on="input">
      <v-text-field v-model="username" :rules="rules" :loading="isLoading"></v-text-field>
      <div class="mt-10 flex justify-end">
        <v-btn color="primary" icon :disabled="!isValid" @click="next">
          <v-icon size="32" icon="mdi-chevron-right" />
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<style scoped lang="scss"></style>
