<script setup lang="ts">

</script>

<template>
  <main class="flex justify-center items-start">
    <div class="mobile-container">
      <slot />
    </div>
  </main>
</template>
<style scoped lang="scss">
.mobile-container {
  width: 100%;
  max-width: 600px;
  padding: 0 16px;
}

</style>
