<script setup lang="ts">
import AnimeText from '@/components/global/AnimeText.vue'
import { useRouter } from 'vue-router'
import ParallaxImage from '@/components/global/ParallaxImage.vue'
import { useRes } from '@/composables/useRes'
import { useDisplay } from 'vuetify'
import { useAuth } from '@/composables/useFirebase'
import { useBookStore } from '@/stores/book'

const router = useRouter()
const { smAndUp } = useDisplay()
const bookStore = useBookStore()
const { books } = bookStore
function goLogin() {
  router.push('/dinner-form')
}
</script>

<template>
  <div class="banner flex justify-center relative overflow-x-hidden">
    <div class="banner-text p-10">
      <anime-text
        class="headline mb-4 sm:mb-12 on-surface-text w-[700px] whitespace-pre-line"
        :text="'독서,\n공유,\n우리들의 오고토 북클럽'"
        :gap="smAndUp ? 30 : 8"
      />
      <div class="bg-white p-16">
        <h2 class="headline-small">Shop</h2>
        <p class="title-medium" style="color: #757575">북클럽 참여하기!</p>
        <div class="flex flex-column gap-6 mt-12">
          <div
            class="book-card p-6 flex outline-gray-600 gap-6"
            v-for="(book, i) in books"
            :key="i"
          >
            <img :src="useRes(book.img)" alt="" />
            <div>
              <p class="title-large">{{ book.title }}</p>
              <p class="label-large mt-2" style="color: #757575">{{ book.desc }}</p>
              <v-btn class="mt-4" @click="router.push(`/bookclub/${book.id}`)">구매하기</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/assets/mixins';

.headline {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  z-index: 3;
  position: relative;
  text-shadow: 0 0 1px white;
}
.book-card {
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  img {
    flex-basis: 160px;
    width: 160px;
    height: 160px;
  }
}
@include sm {
  .headline {
    font-size: 64px;
  }
}
</style>
