import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import type { Profile } from '@/models/profile'
import { useStorage } from '@vueuse/core'

export const useProfileStore = defineStore('profile', () => {
  const me = useStorage<{ profile: Profile | null }>('ogoto-me', { profile: null }, localStorage, {
    mergeDefaults: true
  })
  const resId = useStorage<string | null>('ogoto-res-id', null, localStorage, {
    mergeDefaults: true
  })
  // const resId = ref<string | null>(null)
  return { me, resId }
})
