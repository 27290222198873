<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter()
import { useAttrs } from 'vue'

const attrs = useAttrs()
function back() {
  if (attrs.onBack) {
    (attrs as {onBack: () => void}).onBack()
  } else {
    router.back()
  }
}
</script>

<template>
  <header class="flex justify-center">
    <div class="content-container">
      <v-btn icon variant="plain" color="black" @click="back">
        <v-icon icon="mdi-arrow-left" />
      </v-btn>
    </div>
  </header>
</template>
<style scoped lang="scss">
header {
  position: sticky;
}
.content-container {
  max-width: 1024px;
  width: 100%;
}
</style>
